$header-font: "Montserrat", sans-serif;
$text-font: "Open Sans", sans-serif;

:root {
  --main-gold-color: #8adcff;
  --secondary-gold-color: #4eb9e7;
  --main-repeating-pattern: repeating-linear-gradient(
    -45deg,
    #fcd600 0 20px,
    #ffd900 20px 40px
  );
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: $text-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font;
}

h2 {
  font-size: 4rem;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  font-family: $text-font;
}

p a,
.link {
  color: rgb(0, 110, 255);
  transition: color 300ms;
  &:visited {
    color: rgb(0, 110, 255);
  }
  &:hover {
    color: rgb(0, 73, 168);
    text-decoration: underline;
  }
}

ul {
  font-family: $text-font;
}

img {
  max-width: 100%;
}

select {
  font-size: 1rem;
  padding: 0.5em;
  width: 100%;
  max-width: 400px;
  margin: 5px 0;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  transition: border-color 300ms;
  background-color: transparent;
  border-color: #ddd;
  font-family: $text-font;
  &:hover {
    border-color: var(--main-gold-color);
  }
}

input {
  font-size: 1rem;
  padding: 0.5em;
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 1px solid #ddd;
  outline: none;
  font-family: $text-font;

  transition: border-color 300ms;
  &:hover {
    border-color: var(--main-gold-color);
  }
  &[type="checkbox"],
  &[type="radio"] {
    width: auto;
    border: none;
  }
}

input[type="checkbox"] {
  margin: 10px 0;
}

label {
  display: block;
  margin: 10px 0;
}

textarea {
  outline: none;
  font-size: 16px;
  font-family: $text-font;
  width: 100%;
  box-sizing: border-box;
  border-color: #ddd;
  margin: 5px 0;
  padding: 0.3em 0.5em;
  resize: none;
  &:hover,
  &:focus {
    border-color: var(--main-gold-color);
  }
}
