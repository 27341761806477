@keyframes slideup {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

p {
  &.error {
    color: #ff0000;
  }
}

img {
  &.full-page {
    display: flex;
    max-width: 700px;
    margin: 0 auto;
    filter: drop-shadow(3px 3px 3px rgba(163, 163, 159, 0.165));
  }
}

ul {
  &.unstyled {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
}

.container {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 5%;
  @include sm {
    max-width: 540px;
  }
  @include md {
    max-width: 640px;
    padding: 5% 0;
  }
  @include lg {
    max-width: 720px;
  }
  @include xl {
    max-width: 1060px;
  }
}

.field {
  margin: 15px 0;
  .small {
    font-size: 0.8rem;
    color: rgb(50, 50, 50);
    margin: 0;
  }
}

.checkbox {
  display: flex;
  align-items: baseline;
  label {
    margin-left: 10px;
  }
}

.card {
  $radius: 5px;
  background: #fff;
  border-radius: $radius;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &.x {
    margin-right: 1rem;
  }

  &.y {
    margin-bottom: 1.5rem;
  }

  &.current {
    border: 3px solid var(--main-gold-color);
  }

  &.cancelled {
    border: 3px solid #ff3e3e;
    h2 {
      text-decoration: line-through;
    }
  }

  &.square {
    border-radius: 0;
  }

  img {
    object-fit: cover;
    width: 100%;
  }

  .header {
    background: #fcfcfc;
    padding: 1rem 1.5rem;
  }

  .body {
    margin: 0;
    background: #161925;
    color: #fff;
    padding: 1rem 1.5rem;
    height: 100%;
    &.h {
      display: flex;
      justify-content: space-between;
    }
    h3 {
      margin: 0;
      font-size: 1.5rem;
    }
    p {
      margin: 0;
    }
  }
}

.alert {
  padding: 1rem 1.5rem;
  border-width: 1px;
  border-style: solid;
  &.danger {
    border-color: rgb(241, 137, 137);
    background-color: rgb(247, 195, 195);
  }
  &.warning {
    border-color: #ffeeba;
    background-color: #fff3cd;
  }
}
