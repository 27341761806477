.btn {
  outline: none;
  border: none;
  cursor: pointer;
  padding: 15px 40px;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
  background-color: var(--main-gold-color);
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  color: #000;
  font-weight: 300;
  letter-spacing: 1px;
  position: relative;
  margin: 10px 0;
  transition: 200ms all ease;
  &:hover {
    background-color: #76cff5;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  }
  &.secondary {
    background-color: #fff;
    &:hover {
      background-color: #fafafa;
    }
  }
  &.danger {
    background-color: #dc3545;
    color: #fff;
    &:hover {
      background-color: #b82b39;
    }
  }
  &.success {
    background-color: #34f775;
    &:hover {
      background-color: #39e271;
    }
    $shadow: #39e271;
  }
  &:disabled {
    cursor: not-allowed;
    background: #eee;
  }
  &.small {
    padding: 15px 20px;
  }
}
